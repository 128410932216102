// Zircon - VerifyPayment component


import { React, useEffect, useRef } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { Card, CardBody, CardTitle } from 'reactstrap';
import hookup from "./hookup";
import { msg } from './message';
import { getTimeStamp } from './util';


// VerifyPayment is called by the payment provider
//
// https://ourapp.com/verifypayment
//  ?checkout-account=375917
//  &checkout-algorithm=sha256
//  &checkout-amount=2964
//  &checkout-stamp=15336332710015
//  &checkout-reference=192387192837195
//  &checkout-transaction-id=4b300af6-9a22-11e8-9184-abb6de7fd2d0
//  &checkout-status=ok
//  &checkout-provider=nordea
//  &signature=b2d3ecdda2c04563a4638fcade3d4e77dfdc58829b429ad2c2cb422d0fc64080
//
function VerifyPayment({ setAuto, lng }) {

  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isFetching = useRef(false); // Flag to track data fetching

  // console.log(getTimeStamp(), 'VerifyPayment');

  // Extract parameter values
  const payToken = searchParams.get("checkout-transaction-id");
  const checkoutStatus = searchParams.get("checkout-status");

  // console.log('VerifyPayment');
  // console.log("payToken (checkout-transaction-id):", payToken);
  // console.log("checkout-status:", checkoutStatus);

  useEffect(() => {
    // console.log(getTimeStamp(), 'VerifyPayment, first useEffect begins, payToken', payToken);

    if (checkoutStatus !== 'ok') {
      navigate('/regnumber');
      alert(msg('maksu keskeytetty', lng));
      return;
    }

    if (!isFetching.current) {
      isFetching.current = true;

      hookup({payToken: payToken})
        .then((data) => { setAuto(data); navigate('/show'); })
        .catch((error) => { console.error('Error during API call:', error); navigate('/regnumber'); });
    }
    // console.log(getTimeStamp(), 'VerifyPayment, first useEffect ends');
  }, [checkoutStatus]); 

  return (
    <div className="container">
      <br></br>
      <Card className="border-0 custom-card">
        <CardBody>
          <CardTitle className="display-4">*** Haetaan tietoja ***</CardTitle>
        </CardBody>
      </Card>
    </div>
  );
};


export default VerifyPayment;