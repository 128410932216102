// preCheck.js
//

import axios from 'axios';
import { getTimeStamp, logStatus } from './util';


// Send a limited (suppea) request to API to check the regNumber is found
// 
export default function preCheck(regNumber, vin, regType, timeout = 15000) {

  return new Promise(async (resolve, reject) => {

    // check internet connection against ipify.
    // resolve gracefully if there's no connection.
    let ipifyResponse;
    try {
      ipifyResponse = await axios.get('https://api.ipify.org?format=json', { timeout: 3000 });
      if (ipifyResponse.data) {
        logStatus('DEV', 'Internet is available');
      }
    } catch (error) {
      resolve({
        success: false,
        message: 'Ei yhteyttä'
      });
      return;
    }

    const tempRegNumber = regNumber.length > 0 ? regNumber : 'x'; // TODO! Fix x
    const tempRegType = regType.length > 0 ? '?regType=' + regType : '';
    const tempVin = vin.length > 0 ? '&vin=' + vin : '';

    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_LIMITED}${tempRegNumber}${tempRegType}${tempVin}`, { timeout });
      if (response.data?.congestion) {
        resolve({
          success: false,
          message: 'Palvelussa ruuhkaa tai muu'
        });
        console.log("busy");
        return;
      } else if (response.data?.traficomServerError) {
        resolve({
          success: false,
          message: 'palvelinvirhe traficom'
        });
        console.log("not found");
        return;
      } else if (response.data?.vehicleNotFound) {
        resolve({
          success: false,
          message: 'Rekisterinumeroa ei löydy'
        });
        console.log("not found");
        return;
      } else {
        resolve({
          success: true
        });
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        console.error(getTimeStamp(), 'Request canceled');
      } else if (error.code === 'ECONNABORTED') {
        console.error(getTimeStamp(), 'Request timeout');
      } else {
        console.log(getTimeStamp(), 'Error fetching data from API:');
      }
      resolve({
        success: false,
        message: 'Muu virhe kyselyssä',
        details: error.message
      });
    }
  });
}



